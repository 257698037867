import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgZorroAntdModule, NZ_I18N, en_US } from "ng-zorro-antd";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MainSiteComponent } from "./layout/main-site/main-site.component";
import { HeaderComponent } from "./layout/components/shared/header/header.component";
import { FooterComponent } from "./layout/components/shared/footer/footer.component";
import { SuccessMsgModalComponent } from "./layout/components/site-pages/contact/success-msg-modal/success-msg-modal.component";

@NgModule({
  declarations: [
    AppComponent,
    MainSiteComponent,
    HeaderComponent,
    FooterComponent,
    SuccessMsgModalComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    NgZorroAntdModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA], // Add this line
  providers: [{ provide: NZ_I18N, useValue: en_US }],
  bootstrap: [AppComponent],
  entryComponents: [SuccessMsgModalComponent],
})
export class AppModule {}
